
window.addEventListener("load", () => {
	document.getElementById('hamburger-btn').addEventListener("click", () => {
		const menu = document.getElementById("navMenu");
		console.log('menu:');
		console.log(menu);
		if (menu != null)
			menu.classList.toggle("show-menu");
	});
});
